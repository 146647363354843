import React from 'react'
import { Header } from './header'
import { Footer } from './footer'
import * as globalStyles from '../styles/global.module.css'
import '../styles/fonts.css'
import classNames from 'classnames'

type PagePropsType = {
    pageName?: string,
    children?: React.ReactNode,
}

export const Page = ({ pageName, children }: PagePropsType) => {
    return (
        <div>
            <Header/>
            <div className={classNames({})}>{children}</div>
            <Footer />
        </div>
    )
}
