// extracted by mini-css-extract-plugin
export var burger = "header-module--burger--L8rC0";
export var burgerActive = "header-module--burgerActive--NkT0U";
export var logo = "header-module--logo--mTLiM";
export var logoIcon = "header-module--logoIcon--XpuiY";
export var logoName = "header-module--logoName--b-FMU";
export var maxwidth = "header-module--maxwidth---nqfw";
export var navItem = "header-module--navItem--piWYY";
export var navItemActive = "header-module--navItemActive--+kacS";
export var navItemLanguage = "header-module--navItemLanguage--x6zff";
export var navItemMobileOnly = "header-module--navItemMobileOnly--JgOue";
export var navigation = "header-module--navigation--SEHdC";
export var navigationShow = "header-module--navigationShow--poQjn";
export var transparent = "header-module--transparent--KsRjU";
export var wrapper = "header-module--wrapper--uoQUP";