import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import { useOnClickOutside } from '../hooks/use-onclickoutside'
import { useScroll } from '../hooks/use-scroll'
import * as globalStyles from '../styles/global.module.css'
import * as headerStyles from './header.module.css'
import { MaxWidthWrapper } from './max-width-wrapper'

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
    const { t }: { t: any } = useTranslation()
    const { language, languages, originalPath } = useI18next()
    const [toggleNavigation, setToggleNavigation] = React.useState(false)
    const [isOnTop, setIsOnTop] = React.useState(true)
    const menuRef = React.useRef(null)

    useOnClickOutside((isInside) => setToggleNavigation(isInside), [menuRef])

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    subtitle
                    menuLinks {
                        name
                        type
                        link
                    }
                }
            }
        }
    `)

    useScroll(() => {
        setIsOnTop(window.scrollY === 0)
    })

    return (
        <header
            className={classNames({
                [globalStyles.boxShadow]: !isOnTop,
                [headerStyles.transparent]: isOnTop,
            })}
        >
            <MaxWidthWrapper className={headerStyles.maxwidth}>
                <div className={headerStyles.wrapper} ref={menuRef}>
                    <Link to="/" className={headerStyles.logo}>
                        <div className={headerStyles.logoIcon}>
                            <img
                                className={globalStyles.contain}
                                src="/images/icon-in-circle.png"
                            />
                        </div>
                        <div className={headerStyles.logoName}>
                            <b>{data.site.siteMetadata.title}</b>
                            {/* <span>{data.site.siteMetadata.subtitle}</span> */}
                        </div>
                    </Link>
                    <button
                        className={classNames(headerStyles.burger, {
                            [headerStyles.burgerActive]: toggleNavigation,
                        })}
                        onClick={() => setToggleNavigation(!toggleNavigation)}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                    <nav
                        className={classNames(headerStyles.navigation, {
                            [headerStyles.navigationShow]: toggleNavigation,
                        })}
                    >
                        {data.site.siteMetadata.menuLinks.map(
                            (link: any, i: number) =>
                                link.type === 'header' ? (
                                    <Link
                                        key={i}
                                        to={link.link}
                                        className={[
                                            headerStyles.navItem,
                                            typeof window !== 'undefined' &&
                                            window.location.pathname
                                                .replace(/\/$/, '')
                                                .endsWith(link.link)
                                                ? headerStyles.navItemActive
                                                : null,
                                        ].join(' ')}
                                    >
                                        {t('NAVIGATION.' + link.name)}
                                    </Link>
                                ) : null
                        )}

                        <a
                            className={headerStyles.navItem}
                            href="mailto:ask@voltane.eu"
                        >
                            {t('NAVIGATION.CONTACT')}
                        </a>

                        {data.site.siteMetadata.menuLinks
                            .slice(1)
                            .map((link: any, i: number) =>
                                link.type === 'footer' ? (
                                    <Link
                                        key={i}
                                        to={link.link}
                                        className={[
                                            headerStyles.navItem,
                                            headerStyles.navItemMobileOnly,
                                            typeof window !== 'undefined' &&
                                            window.location.pathname
                                                .replace(/\/$/, '')
                                                .endsWith(link.link)
                                                ? headerStyles.navItemActive
                                                : null,
                                        ].join(' ')}
                                    >
                                        {t('NAVIGATION.' + link.name)}
                                    </Link>
                                ) : null
                            )}

                        {languages.map((lng) =>
                            lng !== language ? (
                                <Link
                                    key={lng}
                                    to={originalPath}
                                    language={lng}
                                    className={[
                                        headerStyles.navItem,
                                        headerStyles.navItemLanguage,
                                        lng === language
                                            ? headerStyles.navItemActive
                                            : null,
                                    ].join(' ')}
                                >
                                    {lng.toUpperCase()}
                                </Link>
                            ) : null
                        )}
                    </nav>
                </div>
            </MaxWidthWrapper>
        </header>
    )
}
