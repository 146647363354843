import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from './link'
import * as footerStyles from './footer.module.css'
import * as globalStyles from '../styles/global.module.css'
import { useTranslation } from 'react-i18next'
import { MaxWidthWrapper } from './max-width-wrapper'
import classNames from 'classnames'

export const Footer: React.FC = () => {
    const { t }: { t: any } = useTranslation()
    const data: any = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    menuLinks {
                        name
                        type
                        link
                        icon
                    }
                    contact {
                        firm
                        co
                        street
                        zip
                        city
                        email
                    }
                }
            }
        }
    `)

    return (
        <footer>
            <MaxWidthWrapper className={footerStyles.wrapper}>
                <div className={footerStyles.links}>
                    <div className={footerStyles.logo}>
                        <img
                            className={globalStyles.contain}
                            src="/images/icon-in-circle.png"
                        />
                    </div>
                    <div className={footerStyles.group}>
                        <div className={footerStyles.footerHeader}>
                            {t('FOOTER.LEGAL')}
                        </div>
                        <nav className={footerStyles.navigation}>
                            {data.site.siteMetadata.menuLinks.map(
                                (link: any, i: number) =>
                                    link.type === 'footer_legal' ? (
                                        <Link
                                            key={i}
                                            to={link.link}
                                            className={[
                                                footerStyles.footerText,
                                                footerStyles.navItem,
                                            ].join(' ')}
                                        >
                                            {t('NAVIGATION.' + link.name)}
                                        </Link>
                                    ) : null
                            )}
                        </nav>
                    </div>
                    <div
                        className={classNames(
                            footerStyles.group,
                            footerStyles.social
                        )}
                    >
                        <div className={footerStyles.footerHeader}>
                            {t('FOOTER.SOCIAL')}
                        </div>
                        <nav className={footerStyles.navigation}>
                            {data.site.siteMetadata.menuLinks.map(
                                (link: any, i: number) =>
                                    link.type === 'footer_social' ? (
                                        <Link
                                            key={i}
                                            to={link.link}
                                            className={[
                                                footerStyles.footerText,
                                                footerStyles.navItem,
                                            ].join(' ')}
                                        >
                                            {t('NAVIGATION.' + link.name)}
                                        </Link>
                                    ) : null
                            )}
                        </nav>
                    </div>
                    <div
                        className={classNames(
                            footerStyles.group,
                            footerStyles.contact
                        )}
                    >
                        <div className={footerStyles.footerHeader}>
                            {t('FOOTER.CONTACT')}
                        </div>
                        <div className={footerStyles.footerText}>
                            <p>
                                <a
                                    href={`mailto:${data.site.siteMetadata.contact.email}`}
                                >
                                    {data.site.siteMetadata.contact.email}
                                </a>
                            </p>
                            <p>
                                {data.site.siteMetadata.contact.firm}
                                <br />
                                <small>
                                    {data.site.siteMetadata.contact.co}
                                </small>
                                <br />
                                {data.site.siteMetadata.contact.street}
                                <br />
                                {data.site.siteMetadata.contact.zip}{' '}
                                {data.site.siteMetadata.contact.city}
                            </p>
                        </div>
                    </div>
                </div>
            </MaxWidthWrapper>
        </footer>
    )
}
