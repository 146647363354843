import classNames from 'classnames'
import * as React from 'react'
import * as maxWidthWrapperStyles from './max-width-wrapper.module.css'

type MaxWidthWrapperPropsType = {
    flex?: boolean,
    className?: string,
    children?: React.ReactNode,
}

export function MaxWidthWrapper({ flex, className, children }: MaxWidthWrapperPropsType) {
    return (
        <div
            className={classNames(className, maxWidthWrapperStyles.wrapper, {
                [maxWidthWrapperStyles.flex]: flex,
            })}
        >
            {children}
        </div>
    )
}
