import * as React from 'react'
import { Page } from '../components/page'
import { graphql } from 'gatsby'

const ReviewsPage = () => {
    return (
        <Page>
            <h1>Welcome About Us</h1>
        </Page>
    )
}

export default ReviewsPage

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
