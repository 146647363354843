import * as React from 'react'
import { Link as I18Link } from 'gatsby-plugin-react-i18next'

export interface LinkProps extends React.PropsWithChildren {
    to?: string
    target?: string
    className?: string
    onClick?: any
}

export const Link: React.FC<LinkProps> = ({ to, className, children, target, onClick }) => {
    if (to && to.startsWith('/'))
        return (
            <I18Link
                className={className}
                to={to}
                onClick={onClick}
            >
                {children}
            </I18Link>
        )

    return (
        <a
            className={className}
            href={to}
            target={target}
            onClick={onClick}
        >
            {children}
        </a>
    )
}
