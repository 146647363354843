import { useEffect } from 'react'

const useOnClickOutside = (callback: (isInside: boolean) => void, refs: Array<any>) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refs.length === 0) return
            if (
                refs.every(
                    (r) =>
                        r &&
                        r.current !== null &&
                        (r.current
                            ? !r.current.contains(event.target)
                            : !r.contains(event.target))
                )
            ) {
                callback(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [refs, callback])
}

export { useOnClickOutside }
